var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.teamInfo
    ? _c(
        "div",
        { staticClass: "dev-onboard" },
        [
          _c("Navbar"),
          _c("main", [
            _c("div", { staticClass: "container-wrapper" }, [
              _vm._m(0),
              _c("div", { staticClass: "page-content-div" }, [
                _c("div", { staticClass: "center-container" }, [
                  _c("div", { staticClass: "content-title" }, [
                    _vm._v("Specify Interests"),
                  ]),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v(
                      "\n            Which domains and product platforms would you be intereseted in?\n            You can always change these later.\n          "
                    ),
                  ]),
                  _c("div", { staticClass: "form-error" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form_errors.interests[0],
                            expression: "form_errors.interests[0]",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.form_errors.interests[0]) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "section-category" }, [
                    _c("div", { staticClass: "section-title" }, [
                      _vm._v("Product Domains"),
                    ]),
                    _c("div", { staticClass: "section-sub-title" }, [
                      _vm._v(
                        "\n              What industries are you interested in?\n            "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "box-container" },
                      _vm._l(_vm.projectIndustries, function (industry, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "product-chip",
                            class: {
                              "selected-btn": _vm.checkedSelectedItem(
                                industry.tag,
                                "platforms"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectIndustry(industry.tag)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "chip-icon" }, [
                              _c("img", {
                                attrs: {
                                  src: "/img/interests/" + industry.img,
                                  alt: "",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "chip-text" }, [
                              _vm._v(_vm._s(industry.name)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "form-error" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form_errors.specialization[0],
                            expression: "form_errors.specialization[0]",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.form_errors.specialization[0]) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "section-category" }, [
                    _c("div", { staticClass: "section-title" }, [
                      _vm._v("Product Platforms"),
                    ]),
                    _c("div", { staticClass: "section-sub-title" }, [
                      _vm._v(
                        "\n              Which platforms are you well versed in?\n            "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "box-container" },
                      _vm._l(_vm.platforms, function (platform) {
                        return _c(
                          "div",
                          {
                            key: platform.tag,
                            staticClass: "product-chip",
                            class: {
                              "selected-btn": _vm.checkedSelectedItem(
                                platform.tag
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectPlatform(platform.tag)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "chip-icon" }, [
                              _c("img", {
                                attrs: {
                                  src: "/img/interests/" + platform.img,
                                  alt: "",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "chip-text" }, [
                              _vm._v(_vm._s(platform.name)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "form-error" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form_errors.compensation[0],
                            expression: "form_errors.compensation[0]",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.form_errors.compensation[0]) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "section-category" }, [
                    _c("div", { staticClass: "section-title" }, [
                      _vm._v("Compensation"),
                    ]),
                    _c("div", { staticClass: "section-sub-title" }, [
                      _vm._v(
                        "\n              How would you like to be paid? Select all that apply\n            "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "box-container" },
                      _vm._l(_vm.compensationModels, function (item, index) {
                        return _c("ChipButton", {
                          key: index,
                          attrs: {
                            text: item.name,
                            dashedBorder: true,
                            buttonIcon:
                              "img/onboarding/specify_preferences/" + item.img,
                            selected: _vm.checkedSelect(item.value),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleSelectCompensation(item.value)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "btn-container" }, [
                    _c(
                      "button",
                      {
                        staticClass: "grupa-blue-btn",
                        attrs: { disabled: _vm.spinner },
                        on: { click: _vm.handleSaveData },
                      },
                      [
                        _vm._v("\n              Proceed\n              "),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.spinner,
                              expression: "spinner",
                            },
                          ],
                          staticClass: "button-spinner",
                          staticStyle: { "margin-left": "20px" },
                          attrs: { src: "/img/lightbox/preloader.gif" },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-container" }, [
      _c("div", { staticClass: "progress indicator_progress" }, [
        _c("a", { staticClass: "indicator dark" }, [_c("div")]),
        _c("a", { staticClass: "indicator next" }, [_c("div")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }