<template>
  <div v-if="teamInfo" class="dev-onboard">
    <Navbar />
    <main>
      <div class="container-wrapper">
        <div class="top-container">
          <div class="progress indicator_progress">
            <!-- -->
            <a class="indicator dark">
              <div></div>
            </a>
            <a class="indicator next">
              <div></div>
            </a>
          </div>
        </div>
        <div class="page-content-div">
          <div class="center-container">
            <div class="content-title">Specify Interests</div>
            <div class="sub-title">
              Which domains and product platforms would you be intereseted in?
              You can always change these later.
            </div>
            <div class="form-error">
              <div v-show="form_errors.interests[0]">
                {{ form_errors.interests[0] }}
              </div>
            </div>
            <div class="section-category">
              <div class="section-title">Product Domains</div>
              <div class="section-sub-title">
                What industries are you interested in?
              </div>
              <div class="box-container">
                <div
                  v-for="(industry, index) in projectIndustries"
                  :key="index"
                  class="product-chip"
                  :class="{
                    'selected-btn': checkedSelectedItem(
                      industry.tag,
                      'platforms'
                    ),
                  }"
                  @click="handleSelectIndustry(industry.tag)"
                >
                  <div class="chip-icon">
                    <img :src="`/img/interests/${industry.img}`" alt="" />
                  </div>
                  <div class="chip-text">{{ industry.name }}</div>
                </div>
              </div>
            </div>
            <div class="form-error">
              <div v-show="form_errors.specialization[0]">
                {{ form_errors.specialization[0] }}
              </div>
            </div>
            <div class="section-category">
              <div class="section-title">Product Platforms</div>
              <div class="section-sub-title">
                Which platforms are you well versed in?
              </div>

              <div class="box-container">
                <div
                  v-for="platform in platforms"
                  :key="platform.tag"
                  class="product-chip"
                  :class="{ 'selected-btn': checkedSelectedItem(platform.tag) }"
                  @click="handleSelectPlatform(platform.tag)"
                >
                  <div class="chip-icon">
                    <img :src="`/img/interests/${platform.img}`" alt="" />
                  </div>
                  <div class="chip-text">{{ platform.name }}</div>
                </div>
              </div>
            </div>
            <div class="form-error">
              <div v-show="form_errors.compensation[0]">
                {{ form_errors.compensation[0] }}
              </div>
            </div>
            <div class="section-category">
              <div class="section-title">Compensation</div>
              <div class="section-sub-title">
                How would you like to be paid? Select all that apply
              </div>
              <div class="box-container">
                <ChipButton
                  v-for="(item, index) in compensationModels"
                  :key="index"
                  :text="item.name"
                  :dashedBorder="true"
                  :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
                  :selected="checkedSelect(item.value)"
                  v-on:click="handleSelectCompensation(item.value)"
                />
              </div>
            </div>
            <div class="btn-container">
              <button
                class="grupa-blue-btn"
                @click="handleSaveData"
                :disabled="spinner"
              >
                Proceed
                <img
                  src="/img/lightbox/preloader.gif"
                  style="margin-left: 20px"
                  class="button-spinner"
                  v-show="spinner"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { editTeamInfo } from "@/api/team";
import { getDev } from "@/api";
import mixPanel from "@/mixins/mixPanel";
import Navbar from "@/components/Navbar/onboarding_navbar";
import ChipButton from "@/components/chipButton/chipButton";

export default {
  mixins: [mixPanel],
  props: {},
  components: {
    Navbar,
    ChipButton,
  },
  data: () => ({
    options: [],
    projectIndustries: [
      { name: "Finance", tag: "finance", img: "finance.svg" },
      { name: "Travel", tag: "travel", img: "travel.svg" },
      { name: "Security", tag: "security", img: "security.svg" },
      { name: "Education", tag: "education", img: "education.svg" },
      { name: "eCommerce", tag: "eCommerce", img: "eCommerce.svg" },
      { name: "Marketing", tag: "marketing", img: "marketing.svg" },
      { name: "Real Estate", tag: "real_estate", img: "real_estate.svg" },
      { name: "Blockchain", tag: "blockchain", img: "blockchain.svg" },
      { name: "Social Media", tag: "social", img: "social.svg" },
      { name: "Energy", tag: "energy", img: "energy.svg" },
      { name: "HR", tag: "hr", img: "hr.svg" },
      { name: "Hospitality", tag: "hospitality", img: "hospitality.svg" },
      { name: "Gaming", tag: "gaming", img: "gaming.svg" },
      { name: "Legal", tag: "legal", img: "legal.svg" },
      { name: "Others", tag: "others", img: "others.svg" },
    ],
    platforms: [
      {
        name: "Mobile",
        tag: "mobile",
        img: "mobile.svg",
      },
      {
        name: "Desktop App",
        tag: "desktop",
        img: "desktop.svg",
      },
      {
        name: "Data Science",
        tag: "data_science",
        img: "data_science.svg",
      },
      { name: "Web", tag: "web", img: "web.svg" },
      {
        name: "IOTs",
        tag: "iots",
        img: "IOTs.svg",
      },
      {
        name: "ML/AI",
        tag: "ml_ai",
        img: "ml_ai.svg",
      },
      {
        name: "others",
        tag: "others",
        img: "others.svg",
      },
    ],
    form_errors: {
      interests: [],
      specialization: [],
      compensation: [],
    },
    selectedIndustries: [],
    selectedPlatforms: [],
    spinner: false,
    teamInfo: null,
    compensationModels: [
      {
        name: "Cash Only",
        value: 1,
        img: "cash.png",
      },
      {
        name: "A mix of Cash & Equity",
        value: 2,
        img: "cash.png",
      },
      {
        name: "Equity only",
        value: 3,
        img: "cash.png",
      },
    ],
    selectedCompensation: [],
  }),
  created() {
    this.getTeamDetails();
  },
  methods: {
    ...mapActions("devDashboard", ["setTeamDetails"]),
    checkedSelectedItem(value, type) {
      let selected =
        type == "platforms" ? this.selectedIndustries : this.selectedPlatforms;
      const foundItem = selected.find((item) => item == value);
      return foundItem ? true : false;
    },
    handleSelectIndustry(value) {
      let selected = this.selectedIndustries;
      const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.selectedIndustries.push(value);
      } else {
        this.selectedIndustries = selected.filter((item) => item !== value);
      }
    },
    handleSelectPlatform(value) {
      let selected = this.selectedPlatforms;
      const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.selectedPlatforms.push(value);
      } else {
        this.selectedPlatforms = selected.filter((item) => item !== value);
      }
    },
    handleSaveData() {
      const interests = this.selectedIndustries;
      const specialization = this.selectedPlatforms;
      const compensation = this.selectedCompensation;
      this.form_errors = {
        interests: [],
        specialization: [],
        compensation: [],
      };
      if (interests.length == 0) {
        this.form_errors.interests[0] = "Team interests is required";
      }
      if (specialization.length == 0) {
        this.form_errors.specialization[0] =
          "Team specialized platforms is required";
      }
      if (compensation.length == 0) {
        this.form_errors.compensation[0] = "Compensation is required";
      }
      if (
        interests.length > 0 &&
        specialization.length > 0 &&
        compensation.length > 0
      ) {
        this.spinner = true;

        let country = "";
        let timezone = "";
        let city = "";

        if (this.userLocation != null) {
          if (this.userLocation.hasOwnProperty("country_name")) {
            country = this.userLocation.country_name
              ? this.userLocation.country_name
              : "";
          }

          if (this.userLocation.hasOwnProperty("timezone")) {
            timezone = this.userLocation.timezone
              ? this.userLocation.timezone
              : "";
          }

          if (this.userLocation.hasOwnProperty("city")) {
            city = this.userLocation.city ? this.userLocation.city : "";
          }
        }

        /*

        const country = this.userLocation.country_name
          ? this.userLocation.country_name
          : "";


          */

        // const city = this.userLocation.city ? this.userLocation.city : "";

        let payload = {
          interests,
          specialization,
          country,
          timezone,
          city,
          compensation_plans: compensation,
        };

        console.log("editTeamInfo - userLocation :: ", payload);

        editTeamInfo(payload)
          .then((response) => {
            if (response.data.status == 1) {
             // this.$router.push({ name: "team_option" });
              this.$router.push({ name: "dev_dashboard" });
            }
            this.mixPanelTrack({
              event: "SaveTeamInterests",
            });
          })
          .catch((error) => {})
          .finally(() => {
            this.spinner = false;
          });
      }
    },
    async getTeamDetails() {
      await getDev()
        .then((res) => {
          if (res.data.status == 1) {
            this.teamInfo = res.data.data.team;
            this.getTeamInterest(res.data.data.team);
            this.setTeamDetails(res.data);
          }
        })
        .catch((err) => {
          this.$developConsole(err);
          logoutUser();
        });
    },
    getTeamInterest(payload) {
      const { interests, specialization, compensation_plans } = payload;
      if (
        interests &&
        interests != "null" &&
        specialization &&
        specialization != "null"
      ) {
        const newInterest = JSON.parse(interests);
        const newSpecialization = JSON.parse(specialization);
        this.selectedIndustries = newInterest;
        this.selectedPlatforms = newSpecialization;
      }
      if (compensation_plans) {
        const teamCompensation = JSON.parse(compensation_plans);
        this.selectedCompensation = teamCompensation;
      }
    },
    handleSelectCompensation(value) {
      let selected = this.selectedCompensation;
      const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.selectedCompensation.push(value);
      } else {
        this.selectedCompensation = selected.filter((item) => item !== value);
      }
      this.form_errors.compensation = [];
    },
    checkedSelect(value) {
      let selected = this.selectedCompensation;
      const foundItem = selected.find((item) => item == value);
      return foundItem ? true : false;
    },
  },
  computed: {
    ...mapState("userManagement", ["userLocation"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.container-wrapper {
}
.top-container {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}
.indicator_progress {
  display: flex;
  justify-content: center;
  margin: 0px;
  width: 37%;
}
.page-content-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.center-container {
  margin-top: 60px;
  max-width: 496px;
  text-align: left;
}
.content-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.sub-title {
  margin-top: 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
  width: 410px;
}
.form-error {
  text-align: left;
  height: 22px;
  color: red;
  margin: 22px 0px 4px 0px;
}
.section-category {
  /* margin-top: 48px; */
}
.section-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.section-sub-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
}
.box-container {
  display: flex;
  margin-top: 8px;
  gap: 8px;
  flex-wrap: wrap;
}
.product-chip {
  background: #f7f7f8;
  border: 1px dashed #979da5;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
}
.chip-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin-left: 4px;
  margin-top: 1.5px;
}
.selected-btn {
  background: rgba(111, 181, 246, 0.2);
  border: 1px dashed #0781f2;
}
.selected-btn .chip-text {
  color: #0781f2;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 48px 0px;
}

@media (max-width: 768px) {
  .btn-container {
    justify-content: flex-start;
  }
  .center-container {
    padding: 0 5px;
  }
  .sub-title {
    width: unset;
  }
}
</style>
